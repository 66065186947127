import React, {useEffect, useRef, useState} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import Input from "../components/Input";
import GradientButton from "../components/GradientButton";
import {UTILS} from "../commons/utils";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {register, verifyEmail} from "../store/user/userActions";
import Loader from "../components/Loader";
import Button from "../components/Button";
import {USER_EMAIL_VERIFICATION_RESET, USER_REGISTER_RESET} from "../store/user/userConstants";
import {CONFIG} from "../commons/config";


function SignUpForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invalidData, setInvalidData] = useState();

  const { error, loading, user } = useSelector(state => state.register);

  useEffect(() => {
    if(error) {
      const message = error.message;
      setInvalidData(message);
      toast.error(message);
    } else if(user) {
      navigate('/signup?step=verify-otp');
      // dispatch({
      //   type: USER_REGISTER_RESET
      // })
    }
  }, [loading, error, user]);


  const onSignUpHandler = (e) => {
    e.preventDefault();

    const data = UTILS.buildJsonFromForm(e.target);
    if(data && data.password && data.confirm_password && data.password !== data.confirm_password) {
      setInvalidData('Passwords do not match!');
      toast.error('Passwords do not match!');
      return;
    } else if(!UTILS.validatePasswordStrength(data.password)) {
      setInvalidData((
        <div className='flex flex-col'>
          <b>Strong Password must contain:</b>
          <ul className='text-[12px]'>
            <li>Minimum 8 characters.</li>
            <li>Contains lowercase letters.</li>
            <li>Contains uppercase letters.</li>
            <li>Contains number.</li>
            <li>[optional] Contains special characters.</li>
          </ul>
        </div>
      ));
      return;
    } else {
      setInvalidData(null);
    }

    dispatch(register(data));
  }

  return (
    <div className='flex flex-col w-full h-fit px-10 py-12 gap-y-4 bg-!black rounded-xl'>
      <div className='flex flex-row text-3xl font-semibold'>
        Create an account
      </div>

      <div className='flex flex-row text-[15px] text-!grey-200'>
        It can not get more simple!
      </div>

      <form className='flex flex-col gap-y-4' onSubmit={onSignUpHandler}>
        <Input label='Email' type='email' placeholder='jon.doe@email.com' required />
        <Input label='Password' placeholder='******' password required />
        <Input label='Confirm Password' placeholder='******' password required />

        {
          invalidData && (
            <div className='flex flex-row w-full'>
              <p className="text-[14px] text-!red-600">
                {invalidData}
              </p>
            </div>
          )
        }

        <div className='flex flex-row w-full'>
          <p className="text-[14px] text-!grey-200">
            Already have an account?&nbsp;
            <Link to='/login' className="font-medium text-!blue-600 hover:text-!blue-500">Login here</Link>
          </p>
        </div>

        <GradientButton
          className='w-fit'
          type='submit'
          text={loading ? (
            <span className='flex flex-row gap-x-4 m-auto'>
              <Loader />
              <span className='flex flex-col'>Create</span>
            </span>
          ) : 'Create'}
        />
      </form>
    </div>
  )
}


function OtpForm(props) {
  const { context } = props;
  const dispatch = useDispatch();
  const [invalidData, setInvalidData] = useState();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);

  const inputRefs = useRef([]);
  const { error, loading, user } = useSelector(state => state.verifyEmail);

  useEffect(() => {
    if(error) {
      const message = error.message;
      setInvalidData(message);
      toast.error(message);
    } else if(user) {
      toast.success('Email verified successfully!');
      window.location.replace(CONFIG.APP_FRONTEND_URL + '/settings?init=true')
    }
  }, [loading, error, user]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (index, value) => {
    if (isNaN(value)) return;

    setOtp((prev) => {
      const newOtp = [...prev];
      newOtp[index] = value;
      return newOtp;
    });

    if (index < 5 && value !== '') {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const onOtpHandler = (e) => {
    e.preventDefault();

    const enteredOtp = otp.join('');
    if(enteredOtp.length !== 6) {
      setInvalidData('Invalid OTP!');
      toast.error('Invalid OTP!');
    } else {
      setInvalidData(null);
      dispatch(verifyEmail({
        "email": context.email,
        "otp": enteredOtp,
      }));
    }
  }

  return (
    <div className='flex flex-col w-full h-fit px-10 py-12 gap-y-4 bg-!black rounded-xl m-auto'>

      <div className='flex flex-row text-3xl font-semibold'>
        Verify Email
      </div>

      <div className='flex flex-row text-[15px] text-!grey-200'>
        We sent an OTP at your email. Please enter it below.
      </div>

      <form className='flex flex-col gap-y-6' onSubmit={onOtpHandler}>
        <div className='flex flex-col w-full gap-y-2'>
          <div className='flex flex-row w-full h-full gap-x-4 my-4 md:gap-x-4'>
            {
              otp.map((value, index) => (
                <input
                  key={index}
                  ref={(input) => inputRefs.current[index] = input}
                  type='text'
                  maxLength={1}
                  pattern={'[0-9]{1}'}
                  autoComplete='off'
                  inputMode='numeric'
                  value={value}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className='flex flex-col h-full w-full rounded-md border-[1px] border-!grey-400 bg-!grey-400/30 m-auto sm:p-4 p-2 outline-none sm:text-[2rem] text-[1.3rem] text-center'
                />
              ))
            }
          </div>

          {
            invalidData && (
              <div className='flex flex-row w-full'>
                <p className="text-[14px] text-!red-600">
                  {invalidData}
                </p>
              </div>
            )
          }
        </div>

        <div className='flex flex-row w-full'>
          <p className="text-[14px] text-!grey-200">
            Did not receive an OTP?&nbsp;
            <span className="font-medium text-!blue-600 hover:text-!blue-500 cursor-pointer">Resend</span>
          </p>
        </div>

        <div className='flex flex-row w-full items-start gap-x-2'>
          <div className='flex flex-col'>
            <Button
              className='flex flex-col w-fit justify-start'
              type='button'
              text={(
                <span className='flex flex-row gap-x-4 my-auto'>
                  &larr;&nbsp;Back
                </span>
              )}
            />
          </div>

          <div className='flex flex-col'>
            <GradientButton
              className='flex flex-col flex-1 w-fit justify-start'
              type='submit'
              text={loading ? (
                <span className='flex flex-row gap-x-4 my-auto'>
                <Loader />
                <span className='flex flex-col'>Verify</span>
              </span>
              ) : 'Verify'}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

function SignUpScreen(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {user} = useSelector(state => state.register);

  useEffect(() => {
    if(searchParams.get('step') === 'verify-otp') {
      if(!user) navigate('/signup');
    }
  }, [searchParams, user]);

  return (
    <div className='flex flex-row w-full font-Inter text-white'>
      <div className='flex flex-col h-full md:w-[50%] w-[0%]'>
        <div className='absolute inset-0 overflow-hidden z-10 h-full w-full'>
          <img
            className='object-cover w-full h-full min-h-screen'
            src='/media/images/background/abstract-gradient-2.webp'
            alt='abstract gradient background'
          />
        </div>

        <div className='md:flex hidden flex-col h-full w-full z-20 px-14 py-20 gap-y-40'>
          <div className='flex flex-row text-white font-bold md:text-5xl lg:text-6xl text-4xl leading-tight'>
            Experience Interviews like they are real
          </div>

          <div className='flex flex-row text-!off-white md:text-xl lg:text-2xl text-lg leading-tight italic'>
            "He was very impressed. <br />
            He said it felt like real interview."<br />
          </div>
        </div>
      </div>

      <div className='flex flex-col md:px-16 sm:px-8 px-4 py-24 mx-auto md:w-[80%] lg:w-[50%] w-full z-20'>
        {
          (searchParams.get('step') === 'verify-otp' && user) ? (
            <OtpForm context={user} />
          ) : (
            <SignUpForm />
          )
        }
      </div>
    </div>
  );
}

export default SignUpScreen;
