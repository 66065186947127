import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { CONFIG } from "./commons/config";
import { UTILS } from "./commons/utils";
import Modal from "./components/Modal";
import Content from "./layouts/Content";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Layout from "./layouts/Layout";
import Router from "./router/Router";

function App() {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    const token = UTILS.getToken();
    if (
      token &&
      !UTILS.isNull(token) &&
      token.length > 0 &&
      typeof token === "string"
    ) {
      window.location.replace(CONFIG.APP_FRONTEND_URL);
    } else {
      // Check if the browser is Chrome
      console.log(navigator.userAgent.toLowerCase());
      const isChrome = navigator.userAgent.toLowerCase().includes("chrome");
      if (!isChrome) {
        setShowDisclaimer(true);
      }
    }
  }, []);

  return (
    <Layout className="relative h-full w-full bg-!black font-Inter">
      {showDisclaimer && (
        <Modal
          onCloseHandler={() => setShowDisclaimer(false)}
          onConfirmHandler={() => setShowDisclaimer(false)}
          confirmText={"Ok, I'll switch!"}
          cancelText={"Damn! Just go away"}
          message={
            <p className="mb-6">
              To have a better experience, we recommend using Google Chrome and Laptop. If
              you face any issues, please reach out to us at{" "}
              <a href="mailto:support@cognatoai.com" className="text-blue-500">
                support@cognatoai.com
              </a>
            </p>
          }
        />
      )}

      <Toaster />
      <Header />

      <Content>
        <Router />
      </Content>

      <Footer />
    </Layout>
  );
}

export default App;
